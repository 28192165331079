import React, { FC, useContext } from 'react'
import { EnergyEntry as EnergyEntryType } from '../../../@types'
import { EnergyContext } from '../../../context/EnergyContext'
import { useEnergyEntryHook } from '../../../hooks/useEnergyEntryHook'
import { CloseButton } from '../../CloseButton'
import { EntryConsumption } from './EnergyEntryConsumption'
import { EntryId } from './EnergyEntryId'
import { EntryPrice } from './EnergyEntryPrice'
import { EntrySpecial } from './EnergyEntrySpecial'
import { EntryType } from './EnergyEntryType'

type EnergyEntryProps = {
    id: number
    entry: EnergyEntryType
    updateEnergyEntry: (id: number, entry: EnergyEntryType, updateRefreshKey?: boolean) => void
}
export const EnergyEntry: FC<EnergyEntryProps> = ({ id, entry, updateEnergyEntry }) => {
    const { removeEnergyEntry } = useContext(EnergyContext)

    const { updatedEntry, handleChangeEvent } = useEnergyEntryHook({ id, entry, updateEnergyEntry })

    return <div className='card md:text-white my-3 p-4 rounded-2xl border border-primary-500 relative'>
        <CloseButton className='absolute right-0 top-0' onClick={() => removeEnergyEntry(id)} />
        <EntryId id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
        <EntrySpecial id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
        <EntryType id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
        {
            updatedEntry.type &&
            <>
                <EntryPrice id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
                <EntryConsumption key={`${id}${entry.special}`} id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            </>
        }
    </div>
}