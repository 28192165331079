import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { entrySpecialOptions } from '../../../data';
import { msg } from "../../../locale/texts";
import { Entry } from '../../Entry';
import { Select } from '../../Select';

type EntrySpecialType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}

export const EntrySpecial: FC<EntrySpecialType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.SPECIAL

    return <Entry
        title={`${msg.energyEntry.special}`}
        text={`${msg.energyEntry.specialText}`}
    >
        <Select id={`${property}${id}`} name={property} value={entry[property]} onChange={handleChangeEvent} options={entrySpecialOptions} />
    </Entry>
}