import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { msg } from '../../../locale/texts'
import { formatNumber } from '../../../utils'
import { Entry } from '../../Entry'
import { Input } from '../../Input'

type EntryConsumptionType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}
export const EntryConsumption: FC<EntryConsumptionType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.CONSUMPTION

    return <Entry
        title={
            entry.special == 'true' ?
                `${entry.type}${msg.energyEntry.consumptionYes}` :
                `${entry.type}${msg.energyEntry.consumptionNo}`
        }
        text={
            entry.special == 'true' ?
                `${msg.energyEntry.consumptionText1Yes} ${entry.type}${msg.energyEntry.consumptionText2Yes}` :
                `${msg.energyEntry.consumptionText1No} ${entry.type}${msg.energyEntry.consumptionText2No}`
        }
    >
        <Input
            id={`${property}${id}`}
            type="number"
            name={property}
            value={
                entry[property]
                    ? entry[property]
                    : undefined
            }
            decimals={0}
            onChange={handleChangeEvent}
            step={1}
        />
    </Entry>
}