import React, { useContext, useMemo } from 'react'
import { EnergyContext } from '../../../context/EnergyContext'
import { msg } from '../../../locale/texts'
import { printNumber } from '../../../utils'
import { Summary } from '../../Summary'
import { SummaryHeader } from '../../Summary/SummaryHeader'
import { SummaryRow } from '../../Summary/SummaryRow'
import { SummarySection } from '../../Summary/SummarySection'

type EnergyTotal = {
    energy: number
    price: number
    name: string
    average: string
}

export const EnergyTotal = () => {
    const { entries } = useContext(EnergyContext)

    const energies = useMemo(() => {
        let temp: Record<string, EnergyTotal> = {}
        const tempEnergies: EnergyTotal[] = []

        entries.map((entry) => {

            if (!entry.type || !entry.consumption || !entry.price) {
                return
            }

            if (!temp[entry.type]) {
                temp[entry.type] = {
                    energy: 0,
                    price: 0,
                    average: '0',
                    name: entry.type
                }
            }

            temp[entry.type] = {
                ...temp[entry.type],
                energy: temp[entry.type].energy + entry.consumption * 1,
                price: temp[entry.type].price + entry.price * 1,
            }
        })

        for (const e in temp) {
            tempEnergies.push({ ...temp[e], average: (temp[e].price / temp[e].energy).toFixed(4) })
        }

        return tempEnergies

    }, [entries])

    return (
        <div className='flex gap-x-8 mt-3'>
            {energies &&
                energies.map((energy, id) =>
                    <Summary key={`step1-total-${energy.name}-${id}`}>
                        <SummaryHeader>
                            <p className='w-full'>{energy.name}</p>
                        </SummaryHeader>
                        <SummarySection>
                            <SummaryRow title={msg.energyTotal.price} value={`${printNumber(energy.price, 2, 0)} EUR`} />
                            <SummaryRow title={`${energy.name}${msg.energyTotal.energy}`} value={`${printNumber(energy.energy, 0, 0)} kWh`} />
                            <SummaryRow title={msg.energyTotal.average} value={`${printNumber(energy.average, 4, 0)} EUR`} />
                        </SummarySection>
                    </Summary>
                )
            }
        </div>
    )
}
