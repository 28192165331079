import React, { useContext, } from 'react'
import { EnergyContext } from '../../context/EnergyContext'
import { msg } from '../../locale/texts'
import { Button } from '../Button'
import { Step } from '../Step'
import { EnergyEntry } from './EnergyEntry'
import { EnergyTotal } from './EnergyTotal'

export const Step1 = () => {
  const { entries, addEnergyEntry, updateEnergyEntry } = useContext(EnergyContext)

  const nextStep = {
    msg: msg.links.nextStep2,
    link: '/step2'
  }

  return (
    <Step title={msg.step1.title} text={msg.step1.text} nextStep={nextStep}>

      {entries &&
        entries.map((entry, id: number) => {
          return entry.isLinked && <EnergyEntry key={`step-1-${entry.key}`} id={id} entry={entry} updateEnergyEntry={updateEnergyEntry} />
        }
        )
      }

      <Button
        onClick={() => addEnergyEntry()}>
        {msg.addPoint}
      </Button>

      <EnergyTotal />

    </Step>
  )
}