import React, { useState, ChangeEvent, useEffect } from "react";
import { EnergyEntryPropEnum } from "../@enums";
import { EnergyEntry, EnergyUsageType } from "../@types";

type useEnergyEntryHookProps = {
  id: number;
  entry: EnergyEntry;
  updateEnergyEntry: (id: number, entry: EnergyEntry) => void;
};

export const useEnergyEntryHook = ({
  id,
  entry,
  updateEnergyEntry,
}: useEnergyEntryHookProps) => {
  const [updatedEntry, setUpdatedEntry] = useState(entry);

  const handleChangeEvent = (e: ChangeEvent<any>) => {
    if (e && e.target) {
      const isArray = e.target.name.indexOf("[");

      if (!(isArray >= 0)) {
        setUpdatedEntry((oldEntry) => {
          let newEntry = { ...oldEntry, [e.target.name]: e.target.value };
          if (
            [
              EnergyEntryPropEnum.SPECIAL,
              EnergyEntryPropEnum.CONSUMPTION,
              EnergyEntryPropEnum.PRICE,
            ].includes(e.target.name)
          ) {
            let consumption: any = 0;

            if (newEntry.special === "false") {
              if (newEntry.isLinked) {
                consumption = (newEntry.consumption ?? 0) / 12;
              }
            }

            const usages = newEntry.usage.map((usage) => {
              return {
                ...usage,
                consumption,
              };
            });
            newEntry = { ...newEntry, usage: usages };
          }

          return newEntry;
        });
        return;
      }

      setUpdatedEntry((oldEntry: any) => {
        const keys = e.target.name.split("[");
        oldEntry[keys[0]][keys[1]] = e.target.value;
        return { ...oldEntry };
      });
    }
  };

  const handleUsageChange = (usage: EnergyUsageType[]) => {
    setUpdatedEntry((old) => {
      return { ...old, usage };
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateEnergyEntry(id, updatedEntry);
    }, 200);

    return () => clearTimeout(timer);
  }, [updatedEntry]);

  return { handleChangeEvent, updatedEntry, handleUsageChange };
};
