import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { msg } from '../../../locale/texts'
import { formatNumber } from '../../../utils'
import { Entry } from '../../Entry'
import { Input } from '../../Input'

type EntryPriceType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}
export const EntryPrice: FC<EntryPriceType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.PRICE

    return <Entry
        title={`${msg.energyEntry.price} (${entry.type})`}
        text={`${msg.energyEntry.priceText1} ${entry.type} ${msg.energyEntry.priceText2}`}
    >
        <Input
            id={`${property}${id}`}
            type="number"
            name={property}
            value={
                entry[property]
                    ? entry[property]
                    : undefined
            }
            onChange={handleChangeEvent}
            step={0.01}
        />
    </Entry>
}