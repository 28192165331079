

import React, { FC } from 'react'

type CloseButtonProps = {
    onClick: () => any
    className?: string
}

export const CloseButton: FC<CloseButtonProps> = ({
    onClick,
    className
}) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className={`p-2 rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none ${className}`}
        >
            <span className="sr-only">Close menu</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    )
}
