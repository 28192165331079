export enum EnergyEntryPropEnum {
  ID = "id",
  SPECIAL = "special",
  PRICE = "price",
  TYPE = "type",
  CONSUMPTION = "consumption",
  USAGE = "usage",
  MONTH_PRICE = "monthPrice",
  MONTH_CONSUMPTION = "monthConsumption",
}

export enum EnergyEntryUsagePropEnum {
  NAME = "name",
  CONSUMPTION = "consumption",
  PRICE = "price",
  ID = "id",
}

export enum FuelEntryPropEnum {
  PRICE_CONSTANT = "priceConstant",
  AVERAGE_PRICE = "averagePrice",
  CONSUMPTION = "consumption",
}
