import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { msg } from "../../../locale/texts";
import { Entry } from '../../Entry';
import { Input } from '../../Input';

type EntryIdType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}
export const EntryId: FC<EntryIdType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.ID

    return <Entry
        title={`${msg.energyEntry.id}`}
        text={`${msg.energyEntry.idText}`}
    >
        <Input id={`${property}${id}`} name={property} value={entry[property]} onChange={handleChangeEvent} />
    </Entry>
}