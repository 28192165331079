import React, { FC, ChangeEvent, useState, useEffect, useRef } from 'react'

type Option = {
    name: string
    value?: string | number
}

type SelectProps = {
    value: any
    onChange: (value: any) => void
    name: string
    className?: string
    id?: string
    label?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
}

export const Select: FC<SelectProps> = ({
    value,
    name,
    onChange,
    className,
    label,
    placeholder = '...',
    options,
    disabled,
    id
}) => {
    const selectEl = useRef<any>(null);
    const [newEvent, setNewEvent] = useState<ChangeEvent<any>>()

    useEffect(() => {
        if (selectEl && selectEl.current) {
            selectEl.current.timer = setTimeout(() => {
                onChange(newEvent)
            }, 300)
        }
        return () => {
            clearTimeout(selectEl?.current?.timer)
            // selectEl?.current?.focus()
        }
    }, [newEvent])
    return (
        <>
            {label &&
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={name}>
                    {label}
                </label>
            }
            <select
                ref={selectEl}
                disabled={disabled}
                className={`shadow appearance-none border w-full rounded py-2 px-3 text-gray-700 leading-tight ${!disabled && `active:outline-none active:shadow-outline`} ${disabled && 'opacity-70 bg-slate-300'} ${className}`}
                id={id ?? name}
                name={name}
                value={newEvent?.target.value ?? value}
                placeholder={placeholder}
                onChange={(e) => setNewEvent(e)}
            >
                {!value &&
                    <option
                        key={`energy-special-default`}
                        value={undefined}
                    >
                        {placeholder}
                    </option>
                }
                {options &&
                    options.map((option) =>
                        <option
                            key={`energy-special-${option.value ?? option.name}`}
                            value={option.value ?? option.name}>
                            {option.name}
                        </option>
                    )
                }
            </select>
        </>
    )
}
